import {mapGetters} from 'vuex';

export default {
	name: 'Answers',
	i18n: require('./i18n').default,
	data() {
		return {
			faq: [
				{
					question: this.$t('Що потрібно робити для початку роботи'),
					answer: `${this.$t('Для ТОВ')} <br> ${this.$t('Для ФОП')}`,
					id: 'selectedParam-0',
				},
				{
					question: this.$t('Яка вартість кожного оголошення?'),
					answer: this.$t('Вартість публікації залежить від актуальних'),
					id: 'selectedParam-1',
				},
				{
					question: this.$t('Мені не вистачає публікацій, що робити далі?'),
					answer: this.$t('Ви можете в будь-який час'),
					id: 'selectedParam-2',
				},
				{
					question: this.$t('Вказання VIN-коду є обов’язковим?'),
					answer: this.$t('Так, адже про авто'),
					id: 'selectedParam-3',
				},
				{
					question: this.$t('Яка вартість повинна бути вказана в оголошенні?'),
					answer: this.$t('На сторінці вашого рекламного оголошення'),
					id: 'selectedParam-4',
				},
				{
					question: this.$t('Як я можу рекламувати свої оголошення?'),
					answer: this.$t('В головному пошуку Авторіа'),
					id: 'selectedParam-5',
				},
			],
		};
	},
	computed: {
		...mapGetters({
			lang: 'lang/id',
		}),
	},
};
