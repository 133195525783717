import {mapActions, mapGetters} from 'vuex';
import openBillingWindow from '../../../helpers/openBillingWindow';

export default {
	name: 'ChoosePackage',
	data() {
		return {
			showList: true,
			typeId: 3,
			period: 1,
			isShow: false,
		};
	},
	i18n: require('../i18n').default,
	mixins: [require('../../../mixins/slonEventLogger')],
	methods: {
		...mapActions({
			autoDealerBilling_: 'others/autoDealerBilling',
		}),
		autoDealerBilling({packageType = 2}) {
			let params = {
				device: this.isDesktop ? 'desktop' : 'mobile',
				packageType,
				pageName: 'companyAdd',
				period: this.period,
				typeId: this.typeId
			};
			this.autoDealerBilling_(params).then(({orderId = 0}) => {
				if (orderId) {
					openBillingWindow(`${orderId}?personal_account_project_id=1`, '_blank', '');
					this.sendEventToSlonik(829, {click_action: packageType === 2 ? 46 : 45, billing_order_id: orderId});
				} else {
					console.error('Wrong orderId');
				}
			}).catch(e => console.error(e));
		},
	},
	computed: {
		...mapGetters({
			userData_: 'Common/userData',
		}),
		userId() {
			return this._result(this.userData_, 'userId', 0);
		},
		prices() {
			return { start: 6300, standard: 19950, premium: 53865 };
		},
		publications() {
			return { start: 30, standard: 100, premium: 300 };
		},
	},
	mounted() {
		if (new Date() <= new Date('Tue Dec 31 2024 23:59:59 GMT+0300 (Eastern European Standard Time)')) {
			this.isShow = true;
		}
	}
};
