export default {
	messages: {
		ru: {
			'Додавайте авто під пригін': 'Добавляйте авто под пригон от своего бизнеса и продавайте по всей Украине.',
			'Реєструйте свій бізнес з продажу': 'Регистрируйте свой бизнес по продаже под пригон на AUTO.RIA и получайте преимущества официального дилера по продаже б/у авто в Украине. Добавление компании на AUTO.RIA обеспечит вам ряд преимуществ для быстрой продажи авто по всей стране, узнайте больше на странице добавления!',
			'вашого': 'вашего',
			'автомобільного бізнесу': 'автомобильного бизнеса',
			'Бонусні публікації та вигоди на просування': 'Бонусные публикации и выгоды на продвижение',
			'Бізнес-інструменти: розміщення в головному пошуку': 'Бизнес-инструменты: размещение в главном поиске, расширенная статистика, специальный алгоритм для привлечения покупателей',
			'Впізнаваність вашої компанії: логотип, назва': 'Узнаваемость вашей компании: логотип, название и сайт, панорамные фото, список услуг на странице авто, расширенный список услуг на странице компании',
			//
			'Всі переваги': 'Все преимущества',
			'розміщення': 'размещения',
			'Почати продавати': 'Начать продавать',
			'Україні з продажу': 'Украине по продаже',
			'сьогодні': 'сегодня',
			'відвідувачів щодня': 'посетителей ежедневно',
			'тис': 'тыс',
			'покупців щодня': 'покупателей ежедневно',
			'Брендування сторінки вашої компанії': 'Брендирование страницы вашей компании',
			'Панорамні': 'Панорамные',
			'та назва': 'и название',
			'Ваші контакти': 'Ваши контакты',
			'Посилання': 'Ссылка',
			'Ваша компанія в каталозі компаній Авто під замовлення': 'Ваша компания в каталоге компаний Авто под заказ',
			'Ідентифікація на сторінках всіх ваших': 'Идентификация на страницах всех ваших',
			'Всі ваші': 'Все ваши',
			'Більше довіри до ваших пропозицій у пошуку': 'Больше доверия к вашим предложениям в поиске',
			'Зручні інструменти для аналітики й статистики': 'Удобные инструменты для аналитики и статистики',
			'Розміщення в інформері': 'Размещение в информере',
			'Технологія «Коллтрекінг» для роботи з дзвінками': 'Технология «Коллтрекинг» для работы со звонками',
			'База активних покупців': 'База активных покупателей',
			'Детальний звіт ефективності': 'Детальный отчет эффективности',
			'Записи розмов із покупцями': 'Записи разговоров с покупателями',
			'фіксації дзвінків': 'фиксации звонков',
			'Розширена статистика розміщення за всіма показниками': 'Расширенная статистика размещения по всем показателям',
			'Авто під замовлення з-за кордону': 'Авто под заказ из-за границы',
			'Під замовлення': 'Под заказ',
			'Більше переходів': 'Больше переходов',
			'Додатковий трафік на оголошення': 'Дополнительный трафик на объявление',
			'Збільшена кількість відкриттів та переглядів оголошеннь': 'Увеличенное количество открытий и просмотров объявлений',
			'Більша кількість зацікавленних покупців': 'Большее количество заинтересованных покупателей',
			'Сума публікацій': 'Сумма публикаций',
			'Ваші': 'Ваши',
			'Перегляди в пошуку': 'Просмотры в поиске',
			'чати': 'чаты',
			'всього': 'всего',
			'використано': 'использовано',
			'від': 'от',
            'Ви отримуєте додаткові перегляди та дзвінки': 'Вы получаете дополнительные просмотры и звонки',
            'Промо-розсилки ваших авто зацікавленій аудиторії': 'Промо-рассылки ваших авто заинтересованной аудитории',
            'Спеціальні алгоритми для залучення покупців': 'Специальные алгоритмы для привлечения покупателей',
            'Ми підвищуємо рівень довіри до вашої компанії': 'Мы повышаем уровень доверия к вашей компании',
            'Високі місця вашої компанії у пошукових системах': 'Высокие места вашей компании в поисковых системах',
            'Безкоштовна реклама за пошуковими запитами': 'Бесплатная реклама по поисковым запросам',
            'Додатковий трафік на сторінку компанії': 'Дополнительный трафик на страницу компании',
            'Більше переглядів ваших авто покупцями': 'Больше просмотров ваших авто покупателями',
			'Опубліковані авто': 'Опубликованные авто',
			'Відкриття номерів телефону': 'Открытие номеров телефона',
			'Оберіть пакет згідно вашого об’єму роботи': 'Выберите пакет согласно вашему объему работы',
			'вигоди на підняття': 'выгоды на поднятие',
			'Реклама ваших оголошень в пошуку': 'Реклама ваших объявлений в поиске',
			'Підтримка експерта з розміщення': 'Поддержка эксперта по размещению',
			'Активувати': 'Активировать',
			'Прогрес': 'Прогресс',
			'Бізнес': 'Бизнес',
			'Частіше обирають': 'Чаще выбирают',
			'Виберіть пакет для вашої області': 'Выберите пакет для вашей области',
			'місяць': 'месяц',
			'місяці': 'месяца',
			'Автодилер Преміум': 'Автодилер Премиум',
			'безкоштовних публікацій': 'бесплатных публикаций',
			'безкоштовні публікації': 'бесплатные публикации',
			'Для компаній, що працюють у високому ціновому сегменті чи з великою к-стю авто': 'Для компаний, работающих в высоком ценовом сегменте или с большим кол-вом авто',
			'рахунок просування': 'счет продвижения',
			'вигоди': 'выгоды',
			'на необмежену кількість публікацій': 'на неограниченное количество публикаций',
			'на підняття в ТОП': 'на поднятие в ТОП',
			'розширені перевірки': 'расширенные проверки',
			'розширені перевірки2': 'расширенных проверок',
			'частка': 'доля',
			'Схожі оголошення': 'Похожие объявления',
			'Відмітка': 'Отметка',
			'Акція': 'Акция',
			'на ваших оголошеннях в пошуку': 'на ваших объявлениях в поиске',
			'Максимальна економія': 'Максимальная экономия',
			'міс': 'мес',
			'Замовити пакет': 'Заказать пакет',
			'Преміум': 'Премиум',
			'Для компаній, що працюють у низькому ціновому сегменті чи з малою к-стю авто': 'Для компаний, работающих в низком ценовом сегменте или с малым кол-вом авто',
			'розширених перевірки': 'расширенных проверки',
			'блоці': 'блоке',
			'економії': 'экономии',
			'В нас уже продають': 'У нас уже продают',
			'Переглянути всі компанії': 'Посмотреть все компании',
			'Акція продажів': '<span class="bold">Акція</span> — купуй пакет до 31.12 і отримуй <span class="bold">х2 публікацій</span>, та додаткові знижки на ТОП',
		},
		uk: {
			'Додавайте авто під пригін': 'Додавайте авто під пригін від свого бізнесу і продавайте по всій Україні.',
			'Реєструйте свій бізнес з продажу': 'Реєструйте свій бізнес з продажу авто під пригін на AUTO.RIA і отримуйте переваги офіційного дилера з продажу б/в авто в Україні. Додавання компанії на AUTO.RIA забезпечить вам ряд переваг для швидкого продажу авто по всій країні, дізнайтесь більше на сторінці додавання!',
			'вашого': 'вашого',
			'автомобільного бізнесу': 'автомобільного бізнесу',
			'Бонусні публікації та вигоди на просування': 'Бонусні публікації та вигоди на просування',
			'Бізнес-інструменти: розміщення в головному пошуку': 'Бізнес-інструменти: розміщення в головному пошуку, розширена статистика, спеціальний алгоритм для залучення покупців',
			'Впізнаваність вашої компанії: логотип, назва': 'Впізнаваність вашої компанії: логотип, назва і сайт, панорамні фото, перелік послуг на сторінці авто, розширений перелік послуг на сторінці компанії',
			//
			'Всі переваги': 'Всі переваги',
			'розміщення': 'розміщення',
			'Почати продавати': 'Почати продавати',
			'Україні з продажу': 'Україні з продажу',
			'сьогодні': 'сьогодні',
			'відвідувачів щодня': 'відвідувачів щодня',
			'тис': 'тис',
			'покупців щодня': 'покупців щодня',
			'Брендування сторінки вашої компанії': 'Брендування сторінки вашої компанії',
			'Панорамні': 'Панорамні',
			'та назва': 'та назва',
			'Ваші контакти': 'Ваші контакти',
			'Посилання': 'Посилання',
			'Ваша компанія в каталозі компаній Авто під замовлення': 'Ваша компанія в каталозі компаній Авто під замовлення',
			'Ідентифікація на сторінках всіх ваших': 'Ідентифікація на сторінках всіх ваших',
			'Всі ваші': 'Всі ваші',
			'Більше довіри до ваших пропозицій у пошуку': 'Більше довіри до ваших пропозицій у пошуку',
			'Зручні інструменти для аналітики й статистики': 'Зручні інструменти для аналітики й статистики',
			'Розміщення в інформері': 'Розміщення в інформері',
			'Технологія «Коллтрекінг» для роботи з дзвінками': 'Технологія «Коллтрекінг» для роботи з дзвінками',
			'База активних покупців': 'База активних покупців',
			'Детальний звіт ефективності': 'Детальний звіт ефективності',
			'Записи розмов із покупцями': 'Записи розмов із покупцями',
			'фіксації дзвінків': 'фіксації дзвінків',
			'Розширена статистика розміщення за всіма показниками': 'Розширена статистика розміщення за всіма показниками',
			'Авто під замовлення з-за кордону': 'Авто під замовлення з-за кордону',
			'Під замовлення': 'Під замовлення',
			'Більше переходів': 'Більше переходів',
			'Додатковий трафік на оголошення': 'Додатковий трафік на оголошення',
			'Збільшена кількість відкриттів та переглядів оголошеннь': 'Збільшена кількість відкриттів та переглядів оголошеннь',
			'Більша кількість зацікавленних покупців': 'Більша кількість зацікавленних покупців',
			'Сума публікацій': 'Сума публікацій',
			'Ваші': 'Ваші',
			'Перегляди в пошуку': 'Перегляди в пошуку',
			'чати': 'чати',
			'всього': 'всього',
			'використано': 'використано',
			'від': 'від',
            'Ви отримуєте додаткові перегляди та дзвінки': 'Ви отримуєте додаткові перегляди та дзвінки',
            'Промо-розсилки ваших авто зацікавленій аудиторії': 'Промо-розсилки ваших авто зацікавленій аудиторії',
            'Спеціальні алгоритми': 'Спеціальні алгоритми',
            'для залучення покупців': 'для залучення покупців',
            'Ми підвищуємо рівень довіри до вашої компанії': 'Ми підвищуємо рівень довіри до вашої компанії',
            'Високі місця вашої компанії у пошукових системах': 'Високі місця вашої компанії у пошукових системах',
            'Безкоштовна реклама за пошуковими запитами': 'Безкоштовна реклама за пошуковими запитами',
            'Додатковий трафік на сторінку компанії': 'Додатковий трафік на сторінку компанії',
            'Більше переглядів ваших авто покупцями': 'Більше переглядів ваших авто покупцями',
			'Опубліковані авто': 'Опубліковані авто',
			'Відкриття номерів телефону': 'Відкриття номерів телефону',
			'Оберіть пакет згідно вашого об’єму роботи': 'Оберіть пакет згідно вашого об’єму роботи',
			'вигоди на підняття': 'вигоди на підняття',
			'Реклама ваших оголошень в пошуку': 'Реклама ваших оголошень в пошуку',
			'Підтримка експерта з розміщення': 'Підтримка експерта з розміщення',
			'Активувати': 'Активувати',
			'Прогрес': 'Прогрес',
			'Бізнес': 'Бізнес',
			'Частіше обирають': 'Частіше обирають',
			'Виберіть пакет для вашої області': 'Виберіть пакет для вашої області',
			'місяць': 'місяць',
			'місяці': 'місяці',
			'Автодилер Преміум': 'Автодилер Преміум',
			'безкоштовних публікацій': 'безкоштовних публікацій',
			'безкоштовні публікації': 'безкоштовні публікації',
			'Для компаній, що працюють у високому ціновому сегменті чи з великою к-стю авто': 'Для компаній, що працюють у високому ціновому сегменті чи з великою к-стю авто',
			'рахунок просування': 'рахунок просування',
			'за допомогою рівнів': 'за допомогою рівнів',
			'вигоди': 'вигоди',
			'на необмежену кількість публікацій': 'на необмежену кількість публікацій',
			'на підняття в ТОП': 'на підняття в ТОП',
			'розширені перевірки': 'розширені перевірки',
			'розширені перевірки2': 'розширених перевірок',
			'частка': 'частка',
			'Схожі оголошення': 'Схожі оголошення',
			'Відмітка': 'Відмітка',
			'Акція': 'Акція',
			'на ваших оголошеннях в пошуку': 'на ваших оголошеннях в пошуку',
			'Максимальна економія': 'Максимальна економія',
			'міс': 'міс',
			'Замовити пакет': 'Замовити пакет',
			'Преміум': 'Преміум',
			'Для компаній, що працюють у низькому ціновому сегменті чи з малою к-стю авто': 'Для компаній, що працюють у низькому ціновому сегменті чи з малою к-стю авто',
			'розширених перевірки': 'розширених перевірки',
			'блоці': 'блоці',
			'економії': 'економії',
			'В нас уже продають': 'В нас уже продають',
			'Переглянути всі компанії': 'Переглянути всі компанії',
			'Акція продажів': '<span class="bold">Акція</span> — купуй пакет до 31.12 і отримуй <span class="bold">х2 публікацій</span>, та додаткові знижки на ТОП',
		}
	}
};
