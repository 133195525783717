import { mapActions } from 'vuex';

export default {
	name: 'LogoList',
	i18n: require('../i18n').default,
	mixins: [require('../../../mixins/slonEventLogger')],
	data() {
		return {
			list: []
		};
	},
	methods: {
		...mapActions({
			fetchDealersLogo_: 'others/dealersLogo',
		}),
		fetchDealersLogo() {
			const params = { limit: 30, type: 3 };
			this.fetchDealersLogo_(params).then(({items = []}) => {
				const randomItems = items.map((object) => ({sort: Math.random(), value: object}))
					.sort((a, b) => a.sort - b.sort)
					.map((a) => a.value);
				this.list = randomItems.slice(0, 6);
			});
		},
	},
	mounted() {
		this.fetchDealersLogo();
	}
};
