export default {
	name: 'LandingOrderCar',
	components: {
		'choose-package': require('./ChoosePackage/index.vue').default,
		'answers': require('./Answers/index.vue').default,
		'logo-list': require('./LogoList/index.vue').default,
		'toggle-screen-shots': require('./ToggleScreenShots/index.vue').default,
		'slider': require('./Slider/index.vue').default,
		'page-header': require('./Header/index.vue').default,
		'info': require('./Info/index.vue').default,
		'personal-manager': require('../Common/PersonalManager/index.vue').default,
		'verified-by-people': require('../../components/Common/VerifiedByPeople/index.vue').default,

	},
	mixins: [require('../../mixins/slonEventLogger')],
	metaInfo() {
		return {
			title: `AUTO.RIA – ${this.$t('Додавайте авто під пригін')}`,
			meta: [
				{name: 'description', content: this.$t('Реєструйте свій бізнес з продажу')},
				{property: 'og:site_name', content: 'AUTO.RIA'},
				{property: 'og:title', content: `AUTO.RIA – ${this.$t('Додавайте авто під пригін')}`},
				{property: 'og:description', content: this.$t('Реєструйте свій бізнес з продажу')},
				{property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png'},
			],
		};
	},
	mounted() {
		this.sendEventToSlonik(828);
	},
	methods: {
		logEvent(action) {
			this.sendEventToSlonik(829, {click_action: action + 60});
		}
	},
	i18n: require('./i18n').default,
};
