export default {
	messages: {
		ru: {
			'Часті запитання': 'Часто задаваемые вопросы',
			'Що потрібно робити для початку роботи': 'Что нужно делать для начала работы?',
			'Для ТОВ': 'Для ООО: выписка из Единого государственного реестра юридических лиц, подписанная с помощью ЭЦП через ЭДО;',
			'Для ФОП': 'Для ФЛП: выписка из Единого государственного реестра физических лиц, копия паспорта, идентификационный код, авторизация в кабинете пользователя через действие и наличие физического офиса на территории Украины.',
			'Яка вартість кожного оголошення?': 'Какова стоимость каждого объявления?',
			'Вартість публікації залежить від актуальних': 'Стоимость публикации зависит от текущих пакетов и количества объявлений в них. Чем больше пакет, тем меньше стоимость объявления. Максимально оптимизировать свои затраты на размещение можно при заблаговременном планировании объемов работы',
			'Мені не вистачає публікацій, що робити далі?': 'Мне не хватает публикаций, что делать дальше?',
			'Ви можете в будь-який час': 'Вы можете в любое время активировать следующий пакет большего или меньшего номинала или оплачивать публикации поштучно. Со своей стороны мы рекомендовали бы использовать пакетные предложения, ведь рациональное использование рекламного бюджета превыше всего',
			'Вказання VIN-коду є обов’язковим?': 'Указание VIN-кода является обязательным?',
			'Так, адже про авто': 'Да, ведь об авто из-за границы покупатель хочет знать максимум информации. Соответственно, чтобы вы не пропустили ни одного VIN-кода – это поле обязательно для заполнения.',
			'Яка вартість повинна бути вказана в оголошенні?': 'Какая стоимость должна быть указана в объявлении?',
			'На сторінці вашого рекламного оголошення': 'На странице вашего объявления есть возможность рассчитать стоимость растаможки, поэтому важно указывать цену без учета растаможки. А в описании обязательно укажите все возможные дополнительные расходы. Такой подход поможет получать звонки от максимально заинтересованных и уже информированных покупателей.',
			'Як я можу рекламувати свої оголошення?': 'Как я могу рекламировать свои объявления?',
			'В головному пошуку Авторіа': 'В главном поиске Авториа есть отдельный блок рекламы. Чтобы ваши автомобили попадали в этот блок и привлекали внимание максимального количества покупателей — поднимайте их в ТОП. При действии пакетов Прогресс или Бизнес вам будут доступны скидки на каждое поднятие в ТОП.',
		},
		uk: {
			'Часті запитання': 'Часті запитання',
			'Що потрібно робити для початку роботи': 'Що потрібно робити для початку роботи?',
			'Для ТОВ': 'Для ТОВ: витяг з Єдиного державного реєстру юридичних осіб, підписаний за допомогою ЕЦП через ЕДО;',
			'Для ФОП': 'Для ФОП: витяг із Єдиного державного реєстру фізичних осіб, копія паспорта, ідентифікаційний код, авторизація в кабінеті користувача через ДІЮ та наявність фізичного офісу на території України.',
			'Яка вартість кожного оголошення?': 'Яка вартість кожного оголошення?',
			'Вартість публікації залежить від актуальних': 'Вартість публікації залежить від актуальних пакетів та кількості оголошень у них. Чим більший пакет, тим менша вартість оголошення. Максимально оптимізувати свої витрати на розміщення можна при завчасному плануванні об’ємів роботи',
			'Мені не вистачає публікацій, що робити далі?': 'Мені не вистачає публікацій, що робити далі?',
			'Ви можете в будь-який час': 'Ви можете в будь-який час активувати наступний пакет більшого чи меншого номіналу, або ж оплачувати публікації поштучно. Зі свого боку ми б рекомендували використовувати пакетні пропозиції, адже раціональне використання рекламного бюджету понад усе',
			'Вказання VIN-коду є обов’язковим?': 'Вказання VIN-коду є обов’язковим?',
			'Так, адже про авто': 'Так, адже про авто з-за кордону покупець хоче знати максимум інформації. Відповідно, щоб ви не пропустили жодного VIN-коду — це поле обов’язкове для заповнення',
			'Яка вартість повинна бути вказана в оголошенні?': 'Яка вартість повинна бути вказана в оголошенні?',
			'На сторінці вашого рекламного оголошення': 'На сторінці вашого рекламного оголошення є можливість розрахувати вартість розмитнення, тому важливо вказувати ціну без урахування розмитнення. А в описі обов’язково вкажіть усі можливі додаткові витрати. Такий підхід допоможе отримувати дзвінки від максимально зацікавлених та вже поінформованих покупців',
			'Як я можу рекламувати свої оголошення?': 'Як я можу рекламувати свої оголошення?',
			'В головному пошуку Авторіа': 'В головному пошуку Авторіа є окремий блок для реклами. Щоб ваші авто потрапляли до цього блоку й привертали увагу максимальної кількості покупців — піднімайте їх в ТОП. При дії пакетів Прогрес чи Бізнес вам будуть доступні знижки на кожне підняття в ТОП',
		}
	}
};
